import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import LPContainer from '~/components/LandingPage/LPContainer'
import { FCC } from '~/core/@types/global'
import { ICONS_SOCIAL } from '~/core/constants/social'
import {
  FACEBOOK_URL,
  HR_OKR_EBOOK_URL,
  INTERVIEW_QUESTION_URL,
  JOB_DESCRIPTIONS_URL,
  LINKEDIN_URL,
  PRICING_URL,
  PRIVACY_POLICY_URL,
  PRODUCT_HUNT_URL,
  TERM_OF_SERVICES_URL,
  URL_BLOG,
  URL_HELP_CENTER,
  X_URL,
  ATTRACT_SOURCE_URL,
  TRACK_COLLABORATE_URL,
  HIRE_ANALYTICS_URL,
  FEATURES_URL
} from '~/core/constants/url'

const FooterTitle: FCC<{ className?: string }> = ({
  children,
  className = ''
}) => {
  return (
    <div
      className={`text-lg tablet:text-xl font-medium text-gray-300 ${className}`}>
      {children}
    </div>
  )
}

const FooterLink: FCC<{
  href: string
  className?: string
  target?: string
}> = ({ href, children, className = '', target }) => {
  return (
    <Link
      href={href}
      target={target}
      className={`block text-base tablet:text-lg hover:underline ${className} max-w-max`}>
      {children}
    </Link>
  )
}

const FooterLandingPage: FC = () => {
  const { t } = useTranslation()
  return (
    <div className="bg-[#0D0B21] text-gray-400">
      <LPContainer>
        <div className="grid grid-cols-[172px_1fr] gap-x-[42px] gap-y-20 pb-10 pt-20 tablet:grid-cols-2 tablet:justify-between tablet:gap-x-[207px] tablet:pb-16 desktop:flex desktop:justify-between desktop:justify-items-stretch desktop:gap-0">
          <div className="">
            <FooterTitle className="mb-4">{t('lp:footer:product')}</FooterTitle>
            <FooterLink
              href={ATTRACT_SOURCE_URL}
              target="_blank"
              className="mb-4">
              {t('lp:footer:attract_source')}
            </FooterLink>
            <FooterLink
              href={TRACK_COLLABORATE_URL}
              target="_blank"
              className="mb-4">
              {t('lp:footer:track_collaboration')}
            </FooterLink>
            <FooterLink
              href={HIRE_ANALYTICS_URL}
              target="_blank"
              className="mb-4">
              {t('lp:footer:hire_analytics')}
            </FooterLink>
            <FooterLink href={FEATURES_URL} target="_blank" className="mb-4">
              {t('lp:footer:all_features')}
            </FooterLink>
            <FooterLink href={PRICING_URL} target="_blank" className="mb-4">
              {t('lp:footer:pricing')}
            </FooterLink>
          </div>
          <div className="">
            <FooterTitle className="mb-4">
              {t('lp:footer:resources')}
            </FooterTitle>
            <FooterLink href={URL_BLOG} className="mb-4">
              {t('lp:footer:blog')}
            </FooterLink>
            <FooterLink
              href={INTERVIEW_QUESTION_URL}
              target="_blank"
              className="mb-4">
              {t('lp:footer:interview_question_template')}
            </FooterLink>
            <FooterLink
              href={JOB_DESCRIPTIONS_URL}
              target="_blank"
              className="mb-4">
              {t('lp:footer:job_description')}
            </FooterLink>
            <FooterLink
              href={HR_OKR_EBOOK_URL}
              target="_blank"
              className="mb-4">
              {t('lp:footer:hr-okr-ebook')}
            </FooterLink>
          </div>
          <div className="">
            <FooterTitle className="mb-4">{t('lp:footer:company')}</FooterTitle>
            <FooterLink href={URL_HELP_CENTER} className="mb-4">
              {t('lp:footer:help_center')}
            </FooterLink>
            {/* <FooterLink href={CONTACT_US_URL} target="_blank" className="mb-4">
              {t('lp:footer:contact_us')}
            </FooterLink> */}
            <FooterLink href={TERM_OF_SERVICES_URL} className="mb-4">
              {t('lp:footer:terms_of_service')}
            </FooterLink>
            <FooterLink href={PRIVACY_POLICY_URL} className="mb-4">
              {t('lp:footer:privacy_policy')}
            </FooterLink>
          </div>
          <div className="">
            <FooterTitle className="mb-4">
              {t('lp:footer:get_in_touch')}
            </FooterTitle>
            <div className="mb-[60px] flex items-center space-x-5">
              <Link href={FACEBOOK_URL} target="_blank">
                <Image
                  src={ICONS_SOCIAL.facebook_darkmode}
                  loading="lazy"
                  width={0}
                  height={0}
                  className="w-[20px] h-auto"
                  alt="Facebook icon"
                />
              </Link>
              <Link href={LINKEDIN_URL} target="_blank">
                <Image
                  src={ICONS_SOCIAL.linkedin_darkmode}
                  loading="lazy"
                  width={0}
                  height={0}
                  className="w-[20px] h-auto"
                  alt="Linkedin icon"
                />
              </Link>
              <Link href={X_URL} target="_blank">
                <Image
                  src={ICONS_SOCIAL.twitter_darkmode}
                  loading="lazy"
                  width={0}
                  height={0}
                  className="w-[20px] h-auto"
                  alt="Twitter icon"
                />
              </Link>
              <Link href={PRODUCT_HUNT_URL} target="_blank">
                <Image
                  src={ICONS_SOCIAL.product_hunt}
                  loading="lazy"
                  width={0}
                  height={0}
                  className="w-[20px] h-auto"
                  alt="product hunt icon"
                />
              </Link>
            </div>
            {/* <FooterLink
              href={pathConfiguration.termOfServices}
              className="mb-4">
              {t('lp:footer:terms_of_service')}
            </FooterLink>
            <FooterLink href={pathConfiguration.privacyPolicy} className="mb-4">
              {t('lp:footer:privacy_policy')}
            </FooterLink> */}
          </div>
        </div>
      </LPContainer>
      <div className="h-px bg-gray-700" />
      <LPContainer>
        <div className="flex flex-wrap items-center justify-between pb-10 pt-6 tablet:py-8">
          <div className="mb-6 mr-1 text-base tablet:text-lg text-gray-400 mobile:mb-0">
            {t('lp:footer:copy_right')}
          </div>
          {/* <LanguageTranslation /> */}
        </div>
      </LPContainer>
    </div>
  )
}

export default FooterLandingPage
