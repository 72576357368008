import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import pathConfiguration from 'src/configuration/path'
import LPContainer from '~/components/LandingPage/LPContainer'
import { ICONS_SOCIAL } from '~/core/constants/social'
import {
  ATTRACT_SOURCE_URL,
  CONTACT_REQUEST_URL,
  CONTACT_US_URL,
  FEATURES_URL,
  HIRE_ANALYTICS_URL,
  HR_OKR_EBOOK_URL,
  PRICING_URL,
  TRACK_COLLABORATE_URL,
  URL_BLOG,
  URL_HELP_CENTER,
  URL_LOGIN_APP,
  URL_REGISTER_APP
} from '~/core/constants/url'
import { Button } from '~/core/ui/Button'
import NavigationBar, { NavItemsProps } from './NavigationBar'
import { useRouter } from 'next/router'
import MobileNavigation from './MobileNavigation'

const navContent: NavItemsProps = [
  {
    label: 'footer:product',
    href: PRICING_URL,
    target: '_self',
    subItems: [
      {
        label: 'footer:attract_source',
        subLabel: 'nav:find_best_talents',
        icon: 'search_nav',
        href: ATTRACT_SOURCE_URL
      },
      {
        label: 'footer:track_collaboration',
        href: TRACK_COLLABORATE_URL,
        subLabel: 'nav:work_with_your_team',
        icon: 'track_nav'
      },
      {
        label: 'footer:hire_analytics',
        href: HIRE_ANALYTICS_URL,
        subLabel: 'nav:data_driven_hiring',
        icon: 'hire_nav'
      },
      { label: 'footer:all_features', href: FEATURES_URL, icon: 'all_feat_nav' }
    ]
  },
  {
    label: 'footer:resources',
    href: '#',
    subItems: [
      {
        label: 'footer:hr-okr-ebook',
        href: HR_OKR_EBOOK_URL,
        icon: 'book_nav'
      },
      { label: 'footer:blog', href: URL_BLOG, icon: 'blog_nav' },
      {
        label: 'footer:help_center',
        href: URL_HELP_CENTER,
        icon: 'help_nav'
      }
    ]
  },
  {
    label: 'footer:contact',
    href: CONTACT_US_URL,
    target: '_self'
  },
  {
    label: 'footer:pricing',
    href: PRICING_URL,
    target: '_self'
  }
]

const HeaderLandingPage: FC<{
  isHidRequestDemoButton?: boolean
  sticky?: boolean
  customContainerHeader?: string
}> = ({
  isHidRequestDemoButton = true,
  customContainerHeader = '',
  sticky
}) => {
  const { t, i18n } = useTranslation()
  const router = useRouter()

  const bg = [CONTACT_REQUEST_URL].includes(router.pathname)
    ? 'bg-[#EBECFF]'
    : 'bg-[#F6F9FE]'

  return (
    <div
      className={`${bg} z-[90] border-b tablet:border-b-0 ${sticky ? 'sticky top-0' : ''}  ${customContainerHeader}`}>
      <LPContainer>
        <div
          className={`flex justify-between items-center py-[24px] tablet:py-[20px]`}>
          <div className="flex items-center">
            <Link href={pathConfiguration?.default}>
              <Image
                src={ICONS_SOCIAL?.hireforce_text}
                height={32}
                width={133}
                alt="hireforce-logo"
                quality={100}
              />
            </Link>

            {/* Desktop Navigation */}
            <NavigationBar content={navContent} />
          </div>

          {/* Desktop actions group Navigation */}
          <div className="hidden items-center gap-6 tablet:flex">
            <Link
              href={URL_REGISTER_APP}
              target="_blank"
              className="font-medium text-[17px] text-gray-900">
              {`${t('lp:try_for_free')}`}
            </Link>
            <Link
              href={URL_LOGIN_APP}
              target="_blank"
              className="font-medium text-[17px] text-gray-900">
              {`${t('lp:sign_in')}`}
            </Link>

            {isHidRequestDemoButton && (
              <Link
                href={pathConfiguration.contactRequest}
                locale={i18n?.language}>
                <Button
                  size="md"
                  label={`${t('lp:book_a_demo')}`}
                  type="quaternary"
                  className="text-lg"
                />
              </Link>
            )}
          </div>

          {/* Mobile Navigation Icon */}
          <MobileNavigation navList={navContent} />
        </div>
      </LPContainer>
    </div>
  )
}

export default HeaderLandingPage
